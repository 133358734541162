// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-persona-js": () => import("./../src/templates/persona.js" /* webpackChunkName: "component---src-templates-persona-js" */),
  "component---src-templates-progetto-js": () => import("./../src/templates/progetto.js" /* webpackChunkName: "component---src-templates-progetto-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-area-studenti-interna-js": () => import("./../src/templates/area-studenti-interna.js" /* webpackChunkName: "component---src-templates-area-studenti-interna-js" */),
  "component---src-templates-reparto-js": () => import("./../src/templates/reparto.js" /* webpackChunkName: "component---src-templates-reparto-js" */),
  "component---src-templates-reparto-interna-js": () => import("./../src/templates/reparto-interna.js" /* webpackChunkName: "component---src-templates-reparto-interna-js" */),
  "component---src-templates-news-ed-eventi-js": () => import("./../src/templates/news-ed-eventi.js" /* webpackChunkName: "component---src-templates-news-ed-eventi-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-aziende-js": () => import("./../src/pages/area-aziende.js" /* webpackChunkName: "component---src-pages-area-aziende-js" */),
  "component---src-pages-area-enti-js": () => import("./../src/pages/area-enti.js" /* webpackChunkName: "component---src-pages-area-enti-js" */),
  "component---src-pages-area-studenti-js": () => import("./../src/pages/area-studenti.js" /* webpackChunkName: "component---src-pages-area-studenti-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-dove-siamo-js": () => import("./../src/pages/dove-siamo.js" /* webpackChunkName: "component---src-pages-dove-siamo-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-e-cookie-js": () => import("./../src/pages/privacy-e-cookie.js" /* webpackChunkName: "component---src-pages-privacy-e-cookie-js" */),
  "component---src-pages-progetti-js": () => import("./../src/pages/progetti.js" /* webpackChunkName: "component---src-pages-progetti-js" */),
  "component---src-pages-reparti-js": () => import("./../src/pages/reparti.js" /* webpackChunkName: "component---src-pages-reparti-js" */),
  "component---src-pages-staff-js": () => import("./../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */)
}

